<template>
    <div id="nyertesek" class="winners block--big bg-primary">
        
            <h2 class="text-center mt-0 text-black mb-5">
                Nyertesek
            </h2>
            <div class="container-xl">
                <div class="row">                
                    <div class="col-12 col-xl-10 offset-xl-1 mb-0 mb-md-5 text-center order-1">
                        <p class="m-0 winners__text text-black">                        
                            <b>Kedves Játékosunk!</b><br><br>

                            Az óránkénti nyeremények nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn, a 2024. december 20. és 2025. január 2. közötti időszakban 2024. december 23-án, 2024. december 27-én, 2024. december 30-án és 2025. január 2-án), míg a heti nyeremények nyertesei péntekenként (2024. november 22., 2024. november 29., 2024. december 6., 2024. december 13., 2024. december 20., 2024. december 27., 2025. január 3.) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.
                            
                            <br><br>A nyerőidőpontok a játék kezdete előtt kerülnek kisorsolásra 7 és 21 óra közötti idősávban.
                            <br><br><b>A fődíj sorsolására 2025. január 6-án 14:00 órakor kerül sor.</b> A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)
                        </p>
                       <!-- <div class="text-white mt-5 custom-winning-box">
                            <span class="fw-bold text-secondary h5 d-block">Sorsolás</span><br>
                            <p>A fődíj, valamint extra nyeremény sorsolására <span class="text-secondary">2024. 07. 22-én 14:00 órakor</span> kerül sor.</p>
                            <p class="fw-bold">A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)</p>
                        </div>-->
                    </div>                                                
                </div>
            </div>
            <div class="winners__winning-wrap">
                <div class="container-xl py-0">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1 text-center">
                            <img src="@/assets/imgs/hero-winnings.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <!-- NYertesek -->
            <div class="col-12 text-center py-3 mt-5">
                <p class="text-black fw-bold fs-22 font-primary">A nyertesek megtekintéséhez <br>kattints az adott napra!</p>
            </div>

            <div class="container-xl block--big py-5 mt-5 bg-white" style="border-radius:20px;">
                <div class="row">
                    <div class="col-12">
                        <!-- TAB -->
                        <div class="winners-button-select mb-5" >
                            <button 
                                class="btn fw-700 px-3" 
                                :class="winnerState === 'orankenti' ? 'active text-black':''" 
                                v-on:click="setWinnerState('orankenti')"
                            >
                                Óránkénti <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>
                            <button 
                                class="btn fw-700 px-3" 
                                :class="winnerState === 'heti' ? 'active text-black':''"  
                                v-on:click="setWinnerState('heti')"
                            >
                                Heti <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button> 
                         <button 
                                class="btn text-gray px-3"
                                :class="winnerState === 'fodij' ? 'active text-black':''" 
                                v-on:click="setWinnerState('fodij')"
                            >
                                Fődíj <span class="d-none d-md-block ms-1"> nyertese</span>
                            </button>
                        </div>
                    </div>
                    
                    <div class="col-12 col-lg-4 text-lg-end text-center mb-3" v-if="winnerState === 'orankenti'">
                        <Datepicker :inline="true"  :lang="lang" :disabled-date="disabledDates" v-model="dateValue" value-type="format" format="YYYY-MM-DD"></Datepicker>
                    </div>
                    <div class="col-12" :class="winnerState !== 'orankenti'? '':'col-lg-7 px-0'">
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-secondary" style="border-bottom: 1px solid #ffffff;">
                                            <th class="w-33 text-white">Nyertes neve</th>
                                            <th class="w-33 text-white">Város</th>       
                                            <th class="w-33 text-white">Nyeremény</th>                      
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length && loginShow">                          
                                        <tr class="winners-list fs-14 text-white" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-white w-33 p-3">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span>{{winner.field_1}}</span>
                                            </td>
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span v-if="winner.type == 'orankenti'">5.000 Ft pénznyeremény</span>
                                                <span v-if="winner.type == 'heti_20'">20.000 Ft pénznyeremény</span>
                                                <span v-if="winner.type == 'heti_50'">50.000 Ft pénznyeremény</span>
                                                <span v-if="winner.type == 'heti_150'">150.000 Ft pénznyeremény</span>
                                                <span v-if="winner.type == 'fodij'">1.002.025 Ft pénznyeremény</span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <!-- v-if="loginShow" -->
                                      <!--  <tr>
                                            <td class="no-winner text-white text-center py-3" colspan="2" v-if="winnerState != 'fodij'">
                                                Hamarosan kiderül, kik lettek a nyertesek
                                            </td>        
                                            <td class="no-winner text-white text-center py-3" colspan="2" v-else>
                                                Hamarosan kiderül, ki lett a nyertes
                                            </td>                            
                                        </tr>-->
                                        <tr>
                                            <td class="no-winner text-center text-white py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-primary" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div>  
            </div> 
            <!-- <div class="col-12">
                <p class="text-center text-white fs-14">A nyeremények képe csak illusztráció!</p>
            </div>-->
        
    </div>
</template>


<script>
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components: {
        Datepicker
    },
    data () {
        return {
            winnersAll:[],
            winnerState:'orankenti',
            needMoreBtn: false,
            needMoreWinners: false,
            dateValue: "",
            refreshKey: 0,
            lang: {
                formatLocale: {
                    // MMMM
                    months: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
                    // MMM
                    monthsShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szept', 'Oct', 'Nov', 'Dec'],
                    // dddd
                    weekdays: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
                    // ddd
                    weekdaysShort: ['Vas', 'Hé', 'Ke', 'Sze', 'Csü', 'Pé', 'Szo'],
                    // dd
                    weekdaysMin: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
                    // first day of week
                    firstDayOfWeek: 1
                }
            }
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            let _this = this
            this.refreshKey;

            if(this.winnersAll ?? []){                
                
                //result = this.winnersAll.filter(w => w.type === this.winnerState)

                _this.winnersAll.forEach(w => {
                    if (_this.winnerState === 'orankenti'){
                        let date_item =  w.winning_time.split(" ")
                        date_item = date_item[0].replaceAll(".","-");
                        //console.log(_this.dateValue, date_item);
                        if(_this.dateValue === date_item){
                            if(w.type === "orankenti"){
                                result.push(w)
                            }
                        }
                    }

                    if (_this.winnerState === 'heti'){
                        if(w.type === 'heti_20' || w.type === 'heti_50' || w.type === 'heti_150'){
                            result.push(w)
                        }
                    }

                    if (_this.winnerState === 'fodij'){
                        if(w.type === 'fodij'){
                            result.push(w)
                        }
                    }


                })

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    watch:{      
        dateValue(){
            this.refreshKey++;

        }
    },
    methods: {
        disabledDates: function (date) {
            return date < new Date(2024, 10, 15) || date > new Date(2025, 0, 2);
        },
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
      /*  selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }*/
    },
    created(){

        this.dateValue = new Date();

        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
         // this.winnerState = "napi";
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })

      //  this.setWinnerState('napi')
    }
    
}
</script>